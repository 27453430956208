import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { makeStyles } from '@material-ui/core/styles'

import MetaTags from '@objects/metatags'
import Container from '@objects/container'
import Button from '@objects/button'
import Headline from '@objects/headline'
import Copy from '@objects/copy'

const useStyles = makeStyles((theme) => ({
  pageRoot: {
    marginTop: theme.spacing(15),
  },
  copy: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(12.5),
  },
}))

function RootIndex({ pageContext }) {
  const classes = useStyles()

  return (
    <Container className={classes.pageRoot}>
      <MetaTags locale={pageContext.locale} meta={{ noIndex: true }} />
      <Headline level={1}>
        <FormattedMessage id="page.404.headline" />
      </Headline>
      <Copy className={classes.copy}>
        <FormattedMessage id="page.404.copy" />
      </Copy>
      <Button to="/">
        <FormattedMessage id="page.404.cta" />
      </Button>
    </Container>
  )
}

RootIndex.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default RootIndex
